import React, { CSSProperties, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import { Divider, Form, Icon, Image, Input } from "semantic-ui-react";

import { ModalComponent } from "./MobVerifyOtp";
import moment from "moment";
import { useIntl } from "react-intl";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type MobHeaderTypes = {
  title?: string;
  leftIconClick?: Function;
  history: any;
  hideButton?: boolean;
  logOut?: boolean;
  onclickLogout?: Function;
  showProfile?: boolean;
  patientData?: any;
};

const COLORS = {
  gray: "#515E63",
  blue: "#2362B2",
  blueLight: "#1F52B4",
};

const styles = {
  infoBackGround: {
    borderRadius: "0 50px 50px 0px",
    margin: "10px 0",
    position: "absolute",
    zIndex: "1500",
    height: "80%",
    display: "flex",
    alignItems: "center",
    padding: " 0 20px 0 0px",
    maxWidth: "90%",
  } as CSSProperties,
  backGround: {
    borderRadius: "0 50px 50px 0px",
    background: "#C1E3EF",
    filter: "blur(2px)",
    position: "absolute",
    zIndex: "1500",
    width: "100%",
    height: "100%",
  } as CSSProperties,
  profileImage: {
    borderRadius: "50px",
    border: "2px solid #8BB6C6",
    marginRight: "5px 0 5px 5px",
    width: "55px",
    height: "55px",
    zIndex: "1500",
    margin: "0 5px",
  } as CSSProperties,
};

const MobHeader = (props: MobHeaderTypes) => {
  const intl = useIntl();
  const handleGoBack = () => {
    if (props.leftIconClick) {
      props.leftIconClick();
    } else {
      props.history.goBack();
    }
  };

  const handleLogOut = () => {
    props.history.goBack();
  };

  const handleCalculateAge = (date: string) => {
    if (date) {
      const DOB = moment(date, "DD/MM/YYYY");
      const duration = moment.duration(moment().diff(DOB));

      return `${duration.years()} ปี ${duration.months()} เดือน ${duration.days()} วัน`;
    } else {
      return ` - `;
    }
  };

  const handleGender = (gender: string) => {
    if (gender.includes("F") || gender.includes("f")) {
      return "/images/ishealth/icon_female.png";
    } else {
      return "/images/ishealth/icon_male.png";
    }
  };

  console.log("saika ~ props.patientData?.pre_name:", props.patientData);

  return (
    <div style={{ position: "sticky", zIndex: "1000", width: "100%" }}>
      {props.showProfile && (
        <div style={styles.infoBackGround}>
          <div style={styles.backGround} />
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={styles.profileImage}>
              {props.patientData.image || props.patientData.image_url ? (
                <Image
                  src={props.patientData.image || props.patientData.image_url}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  circular
                />
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    name="user"
                    size="big"
                    style={{
                      margin: "0",
                      color: "#4087B8",
                      height: "29px",
                      width: "26px",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ zIndex: "1500" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div style={{ color: COLORS.blue, fontWeight: "bold" }}>
                {`HN ${props.patientData?.hn || "-"}`}
              </div>
              {(props.patientData?.gender_value || "").length > 0 && (
                <Image
                  src={handleGender(props.patientData?.gender_value)}
                  style={{ height: "14px" }}
                />
              )}
            </div>
            <div style={{ display: "flex", gap: "40px" }}>
              <div style={{ color: COLORS.blueLight, alignItems: "flex-end" }}>
                {`${props.patientData?.pre_name || "-"} ${
                  props.patientData?.first_name || ""
                } ${props.patientData?.last_name || ""}`}
              </div>
              <div style={{  display: "flex",color: COLORS.blueLight, alignItems: "flex-end", gap: "5px" }}>
                <div style={{  display: "flex" , font:`12px bold`,border: `2px solid ${COLORS.blueLight}`, padding: "2px" , borderRadius:"5px"}}>
                  {"ID"}
                </div>
              <div>{props.patientData?.patient}</div>
              </div>
            </div>

            <div style={{ display: "flex", minWidth: "250px", gap: "5px" }}>
              <div
                style={{
                  display: "flex",
                  color: COLORS.gray,
                  whiteSpace: "nowrap",
                  justifyContent: "center",
                  fontWeight: "bold",
                  gap: "5px",
                }}
              >
                {"วันเกิด"}
                {
                  <span
                    style={{
                      color: COLORS.gray,
                      fontWeight: "normal",
                    }}
                  >
                    {moment(props.patientData?.dob, "DD/MM/YYYY")
                      .locale("th")
                      .format("DD MMM YYYY")}
                  </span>
                }
              </div>
              <div
                style={{
                  display: "flex",
                  color: COLORS.gray,
                  whiteSpace: "nowrap",
                  justifyContent: "center",
                  fontWeight: "bold",
                  gap: "5px",
                }}
              >
                {"อายุ"}
                {
                  <span
                    style={{
                      color: COLORS.gray,
                      fontWeight: "normal",
                    }}
                  >
                    {handleCalculateAge(props.patientData?.dob)}
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="header"
        style={{ ...(props.showProfile && { height: "60px" }) }}
      >
        {props.hideButton ? (
          <div></div>
        ) : (
          <div onClick={() => handleGoBack()}>
            <Icon name="chevron left" />
          </div>
        )}
        <div
          style={{
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          {props.title ? props.title : ""}
        </div>
        {!props.logOut ? (
          <div></div>
        ) : (
          <div onClick={() => props.onclickLogout?.()}>
            <Icon size="large" name="power off" />
          </div>
        )}
      </div>
    </div>
  );
};

MobHeader.displayname = "Login";
export default MobHeader;

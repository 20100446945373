import moment from "moment";
import { type } from "os";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Divider, Modal, TextArea } from "semantic-ui-react";
import { useIntl } from "react-intl";

type ModalDuplicateAppointmentTypes = {
  onEvent?: any;
  open: boolean;
  onClose: Function;
  duplicateAPMList: any[];
  handleCreateAppointmentIH?: any;
  appointmentData?: any;
  setRepetitionNote?: (note: string) => void;
  repetitionNote?: string;
};

const COLORS = {
  primary: "var(--primary-theme-color)",
  blue: "#1F77B4",
  gary: "#B7D7E2",
};

const styles = {
  main_modal: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    borderRadius: "5px",
  },
  title_modal: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px 10px 10px",
    color: COLORS.primary,
    font: "16px bold",
  },
  common_space: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    fontSize: "14px",
  },
};

const MobData = [
  {
    hn: "67000094",
    name: "น.ส. ชาไทย นมสด ",
    status: "มีนัดหมายแล้ว",
    date: "14/0/2565 [16:00]",
    doctor: "พญ.ถนอมพร สุขหรรษา (Main OPD) ",
  },
  {
    hn: "67000095",
    name: "น.ส. ชาไทย นมสด ",
    status: "มีนัดหมายแล้ว",
    date: "14/0/2565 [16:00]",
    doctor: "พญ.ถนอมพร สุขหรรษา (Main OPD) ",
  },
];

type listDupAPMType = {
  hn: string;
  name: string;
  status: number;
  date: string;
  doctor: string;
  division_name: string;
};

const ModalDuplicateAppointment = (props: ModalDuplicateAppointmentTypes) => {
  const intl = useIntl();
  const [textDetail, setTextDetail] = useState<string>("");
  const [listDupAPM, setListDupAPM] = useState<listDupAPMType[]>([]);
  useEffect(() => {
    let listDupAPM: listDupAPMType[] = [];

    props.duplicateAPMList.forEach((item: any) => {
      listDupAPM.push({
        name: `${item.patient_pre_name ? item.patient_pre_name : ""} ${
          item.patient_first_name ? item.patient_first_name : ""
        } ${item.patient_last_name ? item.patient_last_name : ""}`,
        hn: item.patient_hn,
        status: item.status,
        date: item.estimated_at,
        doctor: item.doctor_full_name,
        division_name: item.division_name,
      });
    });

    setListDupAPM(listDupAPM);
  }, [props.duplicateAPMList]);

  const handleShowLabel = () => {
    const selectDateSplit = (props.appointmentData?.selectDate || "").split(
      "-"
    );
    const dateString = `${selectDateSplit[2]}/${selectDateSplit[1]}/${
      parseInt(selectDateSplit[0]) + 543
    }`;

    const selectDate = `${dateString} ${
      props.appointmentData?.time ? `[${props.appointmentData?.time}]` : ""
    }`;
    const doctorName = props.appointmentData?.doctor?.doctor_full_name;
    const divisionName = props.appointmentData?.doctor?.division_name;

    return `${selectDate ? selectDate : ""} ${doctorName ? doctorName : ""} ${
      divisionName ? `(${divisionName})` : ""
    }`;
  };

  return (
    <Modal open={props.open}>
      <div style={styles.main_modal}>
        <div style={styles.title_modal}>{"กรุณาตรวจสอบนัดหมาย"}</div>
        {listDupAPM.map((item: any) => (
          <div style={styles.common_space}>
            <div style={{ color: COLORS.blue }}>
              <div>{`[${item.hn ? item.hn : ""}] ${
                item.name ? item.name : ""
              }`}</div>
            </div>
            <div>
              <div>{item.status === 1 ? "มีนัดหมายแล้ว" : ""}</div>
            </div>
            <div>
              <div>{`${item.date ? item.date : ""}  ${
                item.doctor ? item.doctor : ""
              } ${item.division_name ? `(${item.division_name})` : ""}`}</div>
            </div>
            <Divider
              style={{
                borderStyle: "dashed",
                border: `1px dashed ${COLORS.blue}`,
              }}
            />
          </div>
        ))}
        <div style={styles.common_space}>
          <div>
            <label>{"เหตุผลการนัดหมายซ้ำซ้อน"}</label>
            <label style={{ color: "red" }}>{"*"}</label>
          </div>

          <div>
            <div style={{ color: COLORS.primary, fontWeight: "bold" }}>
              {handleShowLabel()}
            </div>
          </div>
        </div>
        <div style={{ padding: "20px 0px" }}>
          <TextArea
            placeholder={intl.formatMessage({ id: "กรุณาระบุเหตุผลการนัดหมายซ้ำซ้อน" })}
            style={{ width: "100%", height: "75px" }}
            onChange={(e: any, v: any) => {
              props.setRepetitionNote?.(v.value || "");
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
          <Button
            style={{
              background: "#8BB6C6",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() => props.onClose()}
          >
            <div style={{ margin: "0px 20px" }}>
              <FormattedMessage id="common.cancel" />
            </div>
          </Button>
          <Button
            style={{
              background: "#1ABDD4",
              color: "white",
              borderRadius: "20px",
            }}
            disabled={!props.repetitionNote}
            onClick={() => props.handleCreateAppointmentIH()}
          >
            <div style={{ margin: "0px 20px" }}>
              <FormattedMessage id="common.confirm" />
            </div>
          </Button>

          {/* <div
            className="content-common-button"
            onClick={() => props.onClose()}
            style={{ background: COLORS.gary }}
          >
            <div style={{ margin: "0px 20px" }}>
              <FormattedMessage id="common.cancel" />
            </div>
          </div>
          <div
            className="content-common-button"
            // onClick={props.onConfirm}
            style={{ background: COLORS.primary }}
          >
            <div style={{ margin: "0px 20px" }}>
              <FormattedMessage id="common.approve" />
            </div>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

ModalDuplicateAppointment.display = "ModalDuplicateAppointment";
export default React.memo(ModalDuplicateAppointment);

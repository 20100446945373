import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Divider,
  Form,
  Icon,
  Image,
  Input,
  SelectProps,
  Modal,
  Radio,
  Transition,
  InputProps,
  Button,
  List,
  ListItem,
  Label,
} from "semantic-ui-react";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import MessageErrorCurvedDialog from "react-lib/apps/IsHealth/Common/MessageErrorCurvedDialog";
import { useIntl } from "react-intl";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type ChangPasswordTypes = {
  onEvent?: any;
  history: any;
  errorMessage?: string;
  loading?: boolean;
  setProps: any;
  logout?: Function;
};

const colors = {
  primary: "var(--primary-theme-color)",
};

const styles = {
  conditionStyle: {
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
  messageDailogSuccess: {
    margin: "15px",
    padding: "10px",
    color: "#27AE60",
    display: "flex",
    background: "#E6F4E8",
    border: "2px solid #16AA9D",
    borderRadius: "35px",
    font: "15px bold",
    alignItems: "center",
  } as CSSProperties,
  messageDailogError: {
    margin: "15px",
    padding: "10px",
    color: "#ED2026",
    display: "flex",
    background: "#FDD8D5",
    border: "2px solid #F69E97",
    borderRadius: "35px",
    font: "15px bold",
    alignItems: "center",
  } as CSSProperties,
};

const ChangPassword = (props: ChangPasswordTypes) => {
  const intl = useIntl();
  const [hideButton, setHideButton] = useState<boolean>(true);
  const [currentPass, setCurrentPass] = useState<string>("");
  const [newPass, setNewPass] = useState<string>("");
  const [confirmNewPass, setConfirmNewPass] = useState<string>("");
  const [hideCurrentPass, setHideCurrentPass] = useState<boolean>(true);
  const [hideNewPass, setHideNewPass] = useState<boolean>(true);
  const [hideConfirmNewPass, setHideConfirmNewPass] = useState<boolean>(true);
  const [duplicatePass, setDuplicatePass] = useState<boolean>(true);
  const [passwordCheck, setPasswordCheck] = useState<any>({
    lowerCase: false,
    upperCase: false,
    numberCase: false,
    minimum: false,
  });

  useEffect(() => {
    let upperCase = false;
    let lowerCase = false;
    let numberCase = false;

    ["A-Z", "a-z", "0-9"].forEach((value) => {
      const regex = new RegExp(`[${value}]`, "g");
      const test = regex.test(newPass);
      // console.log("saika ~ RegExp ", value, " :", test);

      if (value === "A-Z") {
        upperCase = test;
      }
      if (value === "a-z") {
        lowerCase = test;
      }
      if (value === "0-9") {
        numberCase = test;
      }
    });
    setPasswordCheck({
      lowerCase: lowerCase,
      minimum: newPass.length >= 8,
      upperCase: upperCase,
      numberCase: numberCase,
    });
  }, [newPass, confirmNewPass]);

  const handleCheckField = () => {
    if (
      currentPass.length === 0 ||
      newPass.length === 0 ||
      confirmNewPass.length === 0 ||
      !passwordCheck.lowerCase ||
      !passwordCheck.upperCase ||
      !passwordCheck.numberCase ||
      !passwordCheck.minimum
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpenVerify = () => {
    if (newPass !== confirmNewPass) {
      setDuplicatePass(false);
      return;
    } else {
      setDuplicatePass(true);
    }

    props.onEvent({
      message: "HandleChangePasswordApi",
      params: {
        password: currentPass,
        new_password: newPass,
        confirm_password: confirmNewPass,
      },
    });
  };

  const errorMessage = () => {
    return props.errorMessage?.includes("success");
  };

  return (
    <div className="content-header">
      <MessageErrorCurvedDialog
        open={!!props.errorMessage}
        textMessage={
          errorMessage()
            ? "เปลี่ยนรหัสผ่านสำเร็จ"
            : "ไม่สามารถเปลี่ยนรหัสผ่านได้ เนื่องจากรหัสเดิม ไม่ถูกต้อง"
        }
        styles={
          errorMessage()
            ? styles.messageDailogSuccess
            : styles.messageDailogError
        }
        icon={`/images/ishealth/${
          errorMessage() ? "icon_condition_check" : "icon_close_error"
        }.png`}
        onClose={() => props.setProps("errorMessage", "")}
        otherFunction={() => errorMessage() ? props.logout?.() : ""}
        dimmer={true}
      />

      <div className="content-detail">
        <div style={{ padding: "30px", gap: "10px" }}>
          <label style={{ color: colors.primary }}>{intl.formatMessage({ id: "รหัสผ่านเดิม" })}</label>
          <div>
            <Input
              className="input"
              value={currentPass || ""}
              icon={{
                name: hideCurrentPass ? "eye slash" : "eye",
                link: true,
                onClick: (e: any) => {
                  setHideCurrentPass(!hideCurrentPass);
                },
              }}
              type={hideCurrentPass ? "password" : "input"}
              onChange={(e: any, v: InputProps) => {
                setCurrentPass(v.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
          <label style={{ color: colors.primary }}>{intl.formatMessage({ id: "รหัสผ่านใหม่" })}</label>
          <div>
            <Input
              className="input"
              value={newPass || ""}
              icon={{
                name: hideNewPass ? "eye slash" : "eye",
                link: true,
                onClick: (e: any) => {
                  setHideNewPass(!hideNewPass);
                },
              }}
              type={hideNewPass ? "password" : "input"}
              onChange={(e: any, v: InputProps) => {
                setNewPass(v.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
          <label style={{ color: colors.primary }}>{intl.formatMessage({ id: "ยืนยันหรัสผ่าน" })}</label>
          <div>
            <Input
              className="input"
              value={confirmNewPass || ""}
              icon={{
                name: hideConfirmNewPass ? "eye slash" : "eye",
                link: true,
                onClick: (e: any) => {
                  setHideConfirmNewPass(!hideConfirmNewPass);
                },
              }}
              type={hideConfirmNewPass ? "password" : "input"}
              onChange={(e: any, v: InputProps) => {
                setConfirmNewPass(v.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ minHeight: "3rem" }}>
            {!duplicatePass && (
              <div style={styles.conditionStyle}>
                <Image
                  style={{ width: "24px", marginRight: "10px" }}
                  src="/images/ishealth/icon_warnning_error.png"
                />
                <label style={{ color: "red" }}>
                  รหัสผ่านใหม่ และรหัสผ่านที่ใช้ยืนยันไม่ตรงกัน
                </label>
              </div>
            )}
          </div>
          <div>
            <List>
              <ListItem style={styles.conditionStyle}>
                <Image
                  style={{ width: "24px", marginRight: "10px" }}
                  src={`/images/ishealth/${
                    passwordCheck.minimum
                      ? "icon_condition_check"
                      : "icon_condition"
                  }.png`}
                />
                <label>{intl.formatMessage({ id: "ความยาวรวมไม่น้อยกว่า 8 ตัวอักษร" })}</label>
              </ListItem>
              <ListItem style={styles.conditionStyle}>
                <Image
                  style={{ width: "24px", marginRight: "10px" }}
                  src={`/images/ishealth/${
                    passwordCheck.upperCase
                      ? "icon_condition_check"
                      : "icon_condition"
                  }.png`}
                />
                <label>{intl.formatMessage({ id: "ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว (A-Z)" })}</label>
              </ListItem>
              <ListItem style={styles.conditionStyle}>
                <Image
                  style={{ width: "24px", marginRight: "10px" }}
                  src={`/images/ishealth/${
                    passwordCheck.lowerCase
                      ? "icon_condition_check"
                      : "icon_condition"
                  }.png`}
                />
                <label>{intl.formatMessage({ id: "ตัวอักษรภาษาอังกฤษพิมพ์เล็กอย่างน้อย 1 ตัว (a-z)" })}</label>
              </ListItem>
              <ListItem style={styles.conditionStyle}>
                <Image
                  style={{ width: "24px", marginRight: "10px" }}
                  src={`/images/ishealth/${
                    passwordCheck.numberCase
                      ? "icon_condition_check"
                      : "icon_condition"
                  }.png`}
                />
                <label>{intl.formatMessage({ id: "ตัวเลขอย่างน้อย 1 ตัว" })}</label>
              </ListItem>
            </List>
          </div>

          <div className="div-center-bottom">
            <div className="ishealth-buttom">
              <Button
                disabled={handleCheckField()}
                loading={props.loading}
                onClick={() => handleOpenVerify()}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChangPassword.displayname = "Etc";
export default ChangPassword;

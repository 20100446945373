import React, { CSSProperties, Component, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Dimmer,
  DimmerDimmable,
  Icon,
  Image,
  Transition,
} from "semantic-ui-react";

type MessageErrorCurvedDialogProps = {
  open: boolean;
  icon?: string;
  textMessage: string;
  hideIcon?: boolean;
  showIconEdit?: boolean;
  onClose?: Function;
  onClickEdit?: Function;
  otherFunction?: Function;
  styles?: any;
  dimmer?: boolean;
  disableTimer?: boolean;
  setTimer?: number;
};

const styles = {
  mainDialog: {
    width: "100%",
    position: "fixed",
    top: "5%",
    zIndex: "10000",
    display: "flex",
    height: "auto",
    justifyContent: "center",
  } as CSSProperties,
  messagebox: {
    margin: "15px",
    padding: "15px",
    color: "#E13B40",
    display: "flex",
    background: "#FFE4EB",
    border: "2px solid #F69E97",
    borderRadius: "35px",
    font: "15px bold",
  } as CSSProperties,
};

const Icons = {
  default: "/images/ishealth/icon_error.png",
};

export const MessageErrorCurvedDialog = (
  props: MessageErrorCurvedDialogProps
) => {
  useEffect(() => {
    if (props.open && !props.disableTimer) {
      setTimeout(() => {
        props.onClose?.();
        props.otherFunction?.()
      }, props.setTimer || 2000);
    }
  }, [props.open]);

  return (
    <>
      {props.dimmer ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Dimmer active={props.open} onClick={() => props.onClose?.()}>
            <Transition.Group animation={"fade down"} duration={300}>
              {props.open && (
                <div style={{ ...styles.mainDialog, left: "0" }}>
                  <div style={props.styles ? props.styles : styles.messagebox}>
                    {!props.hideIcon && (
                      <div style={{ width: "40px", marginRight: "5px" }}>
                        <Image src={props.icon || Icons.default} />
                      </div>
                    )}
                    <div style={{ margin: "0 10px" }}>{props.textMessage}</div>
                    {props.showIconEdit && (
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                        onClick={props.onClickEdit?.()}
                      >
                        <div>
                          <Icon name="edit outline" size="large" color="blue" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Transition.Group>
          </Dimmer>
        </div>
      ) : (
        <Transition.Group animation={"fade down"} duration={300}>
          {props.open && (
            <div style={styles.mainDialog} onClick={() => props.onClose?.()}>
              <div style={props.styles ? props.styles : styles.messagebox}>
                {!props.hideIcon && (
                  <div style={{ width: "40px", marginRight: "5px" }}>
                    <Image src={props.icon || Icons.default} />
                  </div>
                )}
                <div style={{ margin: "0 10px" }}>{props.textMessage}</div>
                {props.showIconEdit && (
                  <div
                    style={{ justifyContent: "center", display: "flex" }}
                    onClick={props.onClickEdit?.()}
                  >
                    <div>
                      <Icon name="edit outline" size="large" color="blue" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Transition.Group>
      )}
    </>
  );
};

export default MessageErrorCurvedDialog;

import React, { useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import {
  Button,
  Icon,
  Input,
  InputProps,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import InputOTP from "./InputOTP";
import { registerInfo } from "./TypeModal";

import ModalIshealth from "react-lib/apps/IsHealth/IHMobile/ModalIshealth";
import { useIntl } from "react-intl";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type MobForgotPasswordTypes = {
  onEvent?: any;
  errorMassage?: string;
  errorCheck?: boolean;
  loading?: boolean;
  history: any;
  refCode?: string;
  register?: registerInfo;
  setProps: any;
};

const MobForgotPassword = (props: MobForgotPasswordTypes) => {
  const intl = useIntl();
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleForgotPassword = () => {
    props.onEvent({
      message: "HandleResetPassword",
      params: { history: props.history, email: email },
    });
  };

  const colors = {
    primary: "var(--primary-theme-color)",
  };

  const handleCheckField = () => {
    let disable = false;
    if (email.length === 0) {
      disable = true;
    }

    return disable;
  };

  const closeModal = () => {
    props.setProps("errorMessage", "");
  }

  return (
    <div className="content-header">
      <MobHeader title={intl.formatMessage({ id: "ขอรหัสผ่านใหม่" })}history={props.history} />

      {/* Form Register */}
      <div className="content-detail">
        <div style={{ padding: "30px" }}>
          <div>
            <label  style={{color: colors.primary ,fontSize: "16px"}}>{intl.formatMessage({ id: "อีเมล" })}</label>
            <Input
              className="input"
              value={email || ""}
              type={"input"}
              onChange={(e: any, v: InputProps) => {
                setEmail(v.value);
              }}
              placeholder="Test1234@gmail.com"
              style={{ width: "100%", margin: "10px 0" }}
            />
            <div style={{padding: "10px 0", display:"flex", alignItems: "center"}}>
              <Icon name="info circle" color="blue" size='large'/>
              <label  style={{paddingLeft: "5px", color:"#2362B2"}}>{intl.formatMessage({ id: "ระบุอีเมลที่ใช้สำหรับยืนยันตัวตนที่ลงทะเบียนไว้" })}</label>
            </div>
          </div>

          <div className="div-center-bottom">
            <div className="ishealth-buttom">
              <Button
                disabled={handleCheckField()}
                loading={props.loading}
                onClick={() => handleForgotPassword()}
              >
                ส่งคำขอ
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalIshealth
        open={!!props.errorMassage}
        onClose={() => closeModal}
        title={intl.formatMessage({ id: "ขอรหัสผ่านใหม่" })}
        labelDetail={
          props.errorMassage !== "error"
            ? `ระบบได้รับคำขอของคุณ กรุณาตรวจสอบอีเมล ${email} เพื่อดำเนินการต่อไป`
            : `ขออภัยอีเมลนี้ไม่มีในระบบกรุณาตรวจสอบอีกครั้ง`
        }
        rightText={intl.formatMessage({ id: "ตกลง" })}
        titleStyle={
          props.errorMassage !== "error"
            ? { color: "#27AE60" }
            : { color: "#ED2026" }
        }
        rightOnClick={() => closeModal}
      />
    </div>
  );
};

MobForgotPassword.displayname = "VerifyOtp";
export default MobForgotPassword;

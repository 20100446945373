import React, { SyntheticEvent, useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import {
  Button,
  Divider,
  Form,
  Icon,
  Image,
  Input,
  InputProps,
  Modal,
  Radio,
  RadioProps,
  Transition,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import { registerInfo } from "./TypeModal";

import { type } from "os";
import InputFiledCircular from "./InputFiledCircular";
import MessageErrorCurvedDialog from "react-lib/apps/IsHealth/Common/MessageErrorCurvedDialog";
import { useIntl } from "react-intl";
// import { logoSquare } from "/images/ishealth/logo-square.png";

type MobRegisterTypes = {
  onEvent?: any;
  history?: any;
  loading?: boolean;
  register?: registerInfo;
};

const MobRegister = (props: MobRegisterTypes) => {
  const intl = useIntl();
  const [cardType, setCardType] = useState<string>("cid");
  const [o, setO] = useState<boolean>(false);
  useEffect(() => {
    console.log("saika ~ props.register:", props.register);
  }, [props.register]);

  const handleCheckRegis = () => {
    // props.history.push("/create-password");
    if (!props.loading) {
      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: { key: "cidType", value: cardType },
      });
      props.onEvent({
        message: "HandleRegister",
        params: { viewRegister: "username", history: props.history },
      });
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.register?.error || {};
    if (filed === "password" && props.register?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleCheckField = () => {
    let disable = false;
    if (props.register?.error?.cid || props.register?.error?.username) {
      disable = true;
    }
    return disable;
  };

  const handleChangeCardType = (e: SyntheticEvent, data: RadioProps)=> {
    setCardType(String(data.value));

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "cid", value: "" },
    });
  }

  return (
    <div className="content-header">
      <MobHeader
        title={intl.formatMessage({ id: "ลงทะเบียน" })}
        history={props.history}
      />

      {/* Form Register */}
      <div className="content-detail">
        <div style={{ padding: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Image src="/images/ishealth/register-images.png" />
          </div>

          <div style={{ margin: "20px 0px" }}>
            <label
              style={{ fontSize: "14px", color: "var(--primary-font-color)" }}
            >
              ชื่อผู้ใช้งาน
            </label>
            <div
              style={{
                fontSize: "12px",
                color: "#A2BFC9",
                whiteSpace: "pre-line",
              }}
            >
              {
                "เป็นตัวอักษรภาษาอังกฤษตัวใหญ่ตัวเล็กและตัวเลข \n ความยาวไม่น้อยกว่า 8 ตัวอักษร"
              }
            </div>
          </div>

          <InputFiledCircular
            typeIcon="LOCK"
            error={
              props.register?.error?.username === "USERNAME_AVAILABLE"
                ? "ชื่อผู้ใช้นี้ถูกใช้แล้ว"
                : props.register?.error?.username || ""
            }
            label={intl.formatMessage({ id: "ชื่อผู้ใช้งาน" })}
            value={props.register?.username || ""}
            onChange={(e: any, v: InputProps) => {
              props.onEvent({
                message: "HandleSetValueRegisterInfo",
                params: { key: "username", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("username")}
          />

          <div style={{ margin: "20px 0px" }}>
            <label
              style={{
                fontSize: "14px",
                color: "var(--primary-font-color)",
                margin: "10px 0",
              }}
            >
              ประเภทบัตรแสดงตัวตน
            </label>
          </div>
          <div
            className=".content-filed-radio"
            style={{ display: "flex", marginBottom: "10px" }}
          >
            <Radio
              className="content-filed-radio"
              label={intl.formatMessage({ id: "บัตรประชาชน" })}
              value="cid"
              checked={cardType === "cid"}
              onClick={handleChangeCardType}
            />
            <Radio
              className="content-filed-radio"
              label={intl.formatMessage({ id: "หนังสือเดินทาง" })}
              value="passport"
              checked={cardType === "passport"}
              onClick={handleChangeCardType}
              style={{ margin: "0 10px" }}
            />
          </div>

          <InputFiledCircular
            typeIcon="CID"
            error={
              props.register?.error?.cid === "CID_AVAILABLE"
                ? "หมายเลขบัตรประชาชนถูกใช้แล้ว"
                : props.register?.error?.cid || ""
            }
            value={props.register?.cid || ""}
            onChange={(e: any, v: InputProps) => {
              const input =
                cardType === "cid"
                  ? v.value.replace(/\D/g, "").slice(0, 13)
                  : v.value.slice(0, 10);

              props.onEvent({
                message: "HandleSetValueRegisterInfo",
                params: {
                  key: "cid",
                  value: input,
                },
              });
            }}
            onClickClearError={() => handleClearError("cid")}
          />

          <div className="div-center-bottom">
            <div className="ishealth-buttom">
              <Button
                disabled={handleCheckField()}
                loading={props.loading}
                onClick={() => handleCheckRegis()}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </div>
      <MessageErrorCurvedDialog
        open={!!(props.register?.error?.cid === "CID_AVAILABLE")}
        textMessage={
          "หมายเลขบัตรประชาชนถูกสมัครใช้งานในระบบแล้ว โปรดติดต่อเจ้าหน้าที่เวชระเบียน ของ รพ."
        }
      />
    </div>
  );
};

MobRegister.displayname = "Register";
export default MobRegister;

import React, { useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import { Modal } from "semantic-ui-react";
import { useIntl } from "react-intl";

type ModalIshealthProps = {
  open: boolean;
  onClose: Function;
  title: string;
  labelDetail: string;
  leftText?: string;
  leftOnClick?: Function;
  rightText?: string;
  rightOnClick?: Function;
  titleStyle?: any;
};

export const ModalIshealth = (props: ModalIshealthProps) => {
  return (
    <Modal
      open={props.open}
      style={{
        borderRadius: "20px",
      }}
      onClose={() => props.onClose()}
    >
      <div
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <label
          style={{
            fontSize: "18px",
            color: "#1AB3CB",
            margin: "10px 0",
            whiteSpace: "pre-line",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            lineHeight: "30px",
            fontWeight: "bold",
            ...props.titleStyle
          }}
        >
          {props.title}
        </label>
        <div
          style={{
            fontSize: "14px",
            color: "var(--primary-font-color)",
            whiteSpace: "pre-line",
            display: "flex",
            lineHeight: "20px",
            margin: "10px 0px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {props.labelDetail}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          {props.leftText && (
            <div
              className="content-button"
              style={{ background: "#B7D7E2", minWidth: "130px" }}
              onClick={props.leftOnClick?.()}
            >
              {props.leftText}
            </div>
          )}
          {props.rightText && (
            <div
              className="content-button"
              style={{ minWidth: "130px" }}
              onClick={props.rightOnClick?.()}
            >
              {props.rightText}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

ModalIshealth.displayname = "ModalIshealth";
export default ModalIshealth;

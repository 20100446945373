/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, {
  memo,
  useState,
  useCallback,
  CSSProperties,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";
import { Icon } from "semantic-ui-react";
import { useIntl } from "react-intl";

type OTPInputProps = {
  length: number;
  onChangeOTP: (otp: string) => any;
  refCode: string;
  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  onReSend?: Function;
  onKeyEnter?: Function;
  onClearError?: Function;

  style?: CSSProperties;
  className?: string;

  inputStyle?: CSSProperties;
  inputClassName?: string;
  minuteStart?: number;
  secondsStart?: number;
  errorMessage?: string;
};

const InputOTP = (props: OTPInputProps) => {
  const intl = useIntl();
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    inputClassName,
    inputStyle,
    minuteStart,
    secondsStart,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(""));
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    if (props.refCode) {
      setMinutes(props.minuteStart || 0);
      setSeconds(props.secondsStart || 0);
    } else {
      setMinutes(0);
      setSeconds(0);
    }
  }, [props.refCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(seconds);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [minutes, seconds]);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      let changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : "";
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
      props.onClearError?.()
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Enter": {
          props.onKeyEnter();
          break;
        }
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedDataCheck = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput);

      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");

      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        onChangeOTP(pastedDataCheck);
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <label
          style={{
            fontSize: "20px",
            color: "var(--primary-font-color)",
            margin: "10px 0 25px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            props.onReSend?.();
            console.log("saika ~ onReSend:");
          }}
        >
          {`ขอรหัส OTP อีกครั้ง`}
          {<Icon name="redo" />}
        </label>
      ) : (
        <label
          style={{
            fontSize: "20px",
            color: "var(--primary-font-color)",
            margin: "10px 0 25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {`ภายในเวลา ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
        </label>
      )}

      <div style={{ display: "flex", justifyContent: "center" }} {...rest}>
        {Array(length)
          .fill("")
          .map((_, index) => (
            <SingleInput
              key={`SingleInput-${index}`}
              type={"number"}
              focus={activeInput === index}
              value={otpValues && otpValues[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
              onPaste={handleOnPaste}
              style={inputStyle}
              className={inputClassName}
              disabled={disabled}
            />
          ))}
      </div>
      {props.errorMessage && (
        <label
          style={{
            fontSize: "14px",
            color: "red",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {props.errorMessage}
        </label>
      )}
      <label
        style={{
          fontSize: "14px",
          color: "var(--primary-font-color)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {`ref. code : ${props.refCode ? props.refCode : "-"}`}
      </label>
    </div>
  );
};

type SingleOTPInputProps = {
  focus?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SingleInput = (props: SingleOTPInputProps) => {
  const { focus, autoFocus, ...rest } = props;
  console.log("saika ~ props:", props);
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus]);

  return <input ref={inputRef} {...rest} />;
};

export default React.memo(InputOTP);
